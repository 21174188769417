import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'



export const DEFAULT_META: PageMeta = {
  title: 'Multisign',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  return {
    title: `Multisign`,
    description:"Multisign"
  }
} 
